
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('complain.com_report_search')}}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('complain.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('complain.service')"
              label-for="service_id"
            >
              <b-form-select
                plain
                id="service_id"
                v-model="search.service_id"
                :options="serviceList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('complain.division')"
                label-for="division_id"
                >
                <b-form-select
                    plain
                    id="division_id"
                    v-model="search.division_id"
                    :options="divisionList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('complain.district')"
                label-for="district_id"
                >
                <b-form-select
                    plain
                    id="district_id"
                    v-model="search.district_id"
                    :options="districtList"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('complain.upazila')"
              label-for="upazilla_id"
            >
              <b-form-select
                plain
                id="upazilla_id"
                v-model="search.upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('complain.union')"
              label-for="union_id"
            >
              <b-form-select
                plain
                id="union_id"
                v-model="search.union_id"
                :options="unionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('complain.status')"
              label-for="status"
            >
            <b-form-select
              plain
              v-model="search.status"
              :options="comStatusList"
              id="status"
              name="status"
              >
              <template v-slot:first>
                <b-form-select-option :value="'selected'" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
            <flat-pickr class="form-control"
              v-model="search.from_date"
              placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('globalTrans.to_date')"
                label-for="to_date"
                >
                <flat-pickr class="form-control"
                v-model="search.to_date"
                placeholder="Select Date"
                ></flat-pickr>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-if="complainInfoDataList.length !== 0">
      <b-col md="12">
        <iq-card>
            <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('complain.com_report_list') }}</h4>
            </template>
            <template v-slot:headerAction>
                <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                    class="btn btn-success mr-2"
                    :data="dataCustomize"
                    :fields= "json_fields"
                    :title="$t('complain.com_report_list')"
                    worksheet="Complain Report Sheet"
                    name="filename.xls">
                    {{ $t('globalTrans.export_excel') }}
                </export-excel>
            </template>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <b-table ref="complainTableId" id="complainTableId" bordered hover :items="listData" :fields="columns"  aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(com_title)="data">
                                    <span class="capitalize">{{ data.item.com_title }}</span>
                                </template>
                                <template v-slot:cell(org_id)="data">
                                    <span class="capitalize">{{ data.item.org_id }}</span>
                                </template>
                                <template v-slot:cell(service_id)="data">
                                    <span class="capitalize">{{ data.item.service_id }}</span>
                                </template>
                                <template v-slot:cell(status)="data">
                                    {{ data.item.status }}
                                </template>
                                <template v-slot:cell(action)="data">
                                    <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="view(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
      <b-modal v-if="viewModal" id="modal-4" size="lg" :title="$t('complain.complain_info_detail')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-container fluid>
        <b-row>
          <b-col lg="12" sm="12">
            <iq-card>
              <template>
                <b-form>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group label-cols-sm="3" :label="$t('complain.organization')">
                        <b-form-input v-model="complain_detail.org_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.division')">
                        <b-form-input v-model="complain_detail.division_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.upazila')">
                        <b-form-input v-model="complain_detail.upazilla_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.com_title')">
                        <b-form-input v-model="complain_detail.com_title" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.email')">
                        <b-form-input v-model="complain_detail.email" readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group label-cols-sm="3" :label="$t('complain.service')">
                        <b-form-input v-model="complain_detail.service_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.district')">
                        <b-form-input v-model="complain_detail.district_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.union')">
                        <b-form-input v-model="complain_detail.union_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.com_title_bn')">
                        <b-form-input v-model="complain_detail.com_title_bn" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('complain.mobile_no')">
                        <b-form-input v-model="complain_detail.mobile_no" readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-form-group label-cols-sm="2" :label="$t('complain.description')">
                        <p class="text-dark" v-html="complain_detail.description" readonly></p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { commonServiceBaseUrl, irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
// ?? import ListReportHead from '@/components/custom/ListReportHead.vue'
Vue.use(excel)

const excelColumn = {
    Organization: 'org',
    'Complain Title': 'comTitle',
    Service: 'service',
    Status: 'status'
}
const excelColumnBn = {
    সংস্থা: 'org',
    'অভিযোগের টাইটেল': 'comTitle',
    সার্ভিস: 'service',
    অবস্থা: 'status'
}
export default {
    name: 'UiDataTable',
    components: {
        // ListReportHead
    },
    data () {
        return {
            pagination: {
                currentPage: 1,
                totalRows: 0,
                slOffset: 1
            },
            search: {
                com_title: '',
                org_id: 0,
                service_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                union_id: 0,
                status: 'selected',
                from_date: '',
                to_date: ''
            },
            testId: 0,
            rows: [],
            services: [],
            complainInfoDataList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            complain_detail: '',
            createEditModal: false,
            viewModal: false,
            commonServiceBaseUrlImg: commonServiceBaseUrl
        }
    },
    computed: {
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            const complainInfoDataList = this.complainInfoDataList
            complainInfoDataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    item.org = item.org_name_bn
                    item.comTitle = item.com_title_bn
                    item.service = item.service_name_bn
                    item.status = item.status_name_bn
                } else {
                    item.org = item.org_name
                    item.comTitle = item.com_title
                    item.service = item.service_name
                    item.status = item.status_name
                }
                return Object.assign({}, item)
            })
            return complainInfoDataList
        },
        comStatusList: function () {
        const listObject = this.$store.state.commonObj.comStatusList
        const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
            } else {
                return { value: obj.id, text: obj.name }
            }
        })
        return tmpList
        },
        formTitle () {
        return (this.testId === 0) ? this.$t('complain.submit_complain_info') + ' ' + this.$t('globalTrans.entry') : this.$t('complain.submit_complain_info') + ' ' + this.$t('globalTrans.modify')
        },
        loading () {
        return this.$store.state.commonObj.loading
        },
        listReload () {
        return this.$store.state.commonObj.listReload
        },
        loadingState () {
        if (this.listReload) {
            return true
        } else if (this.loading) {
            return true
        } else {
            return false
        }
        },
        listData () {
        return this.complainInfoDataList
        },
        orgList: function () {
          return this.$store.state.orgList.filter(item => item.status === 0)
        },
        serviceList () {
        const services = this.$store.state.commonObjCommonConfig.serviceList.filter(item => item.status === 0)
        const listData = services.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
        return listData
        },
        divisionList () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('complain.organization'), class: 'text-left' },
            { label: this.$t('complain.com_titlel'), class: 'text-left' },
            { label: this.$t('complain.service'), class: 'text-left' },
            { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'org_name_bn' },
            { key: 'com_title_bn' },
            { key: 'service_name_bn' },
            { key: 'status_name_bn' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'org_name' },
            { key: 'com_title' },
            { key: 'service_name' },
            { key: 'status_name' },
            { key: 'action' }
            ]
        }
        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
        }
    },
    watch: {
        'search.division_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
            this.districtList = this.getDistrict(newVal)
        }
        },
        'search.district_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
            this.upazilaList = this.getUpazila(newVal)
        }
        },
        'search.upazilla_id': function (newVal, oldVal) {
        if (oldVal !== newVal) {
            this.unionList = this.getUnion(newVal)
        }
        }
    },
    async created () {
        // this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        resetId () {
        this.testId = 0
        this.createEditModal = true
        this.viewModal = false
        },
        testClick () {
        this.loadData()
        },
        searchData () {
            if (this.search.org_id !== 0) {
                this.loadData()
            }
        },
        view (item) {
        this.complain_detail = item
        this.viewModal = true
        this.createEditModal = false
        },
        async loadData () {
        const result = await RestApi.getData(commonServiceBaseUrl, '/complain-info/list', this.search)
        if (result.success) {
            this.complainInfoDataList = this.dataList(result.data.data)
        }
        },
        dataList (data) {
            const objectData = this.$store.state.commonObj.comStatusList
            const typesData = data.map(item => {
                const tmpData = objectData.find(statusItem => statusItem.id === item.status)
                const typeData = { status_name: tmpData.name, status_name_bn: tmpData.name_bn }
                return Object.assign({}, item, typeData)
            })
            return typesData
        },
        getDistrict (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
        },
        getUpazila (districtId = null) {
        const upazilas = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        if (districtId) {
            return upazilas.filter(upazila => upazila.district_id === districtId)
        }
        return upazilas
        },
        getUnion (upazilaId = null) {
        const uninons = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
            return uninons.filter(uninon => uninon.upazilla_id === upazilaId)
        }
        return uninons
        },
        pdfExport () {
        const rowData = this.getPdfData()
        const labels = [
            { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('complain.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
            { text: this.$t('complain.com_title'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
            { text: this.$t('complain.service'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true },
            { text: this.$t('globalTrans.status'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), bold: true }
            ]
        rowData.unshift(labels)
        const reportTitle = this.$i18n.locale === 'en' ? 'Complain Report' : 'অভিযোগ  রিপোর্ট'
        const columnWids = ['*', '*', '*', '*', '*']
        ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
        },
        getPdfData () {
            const keys = [
                { key: 'serial_no' },
                { key: this.$i18n.locale === 'en' ? 'org_name' : 'org_name_bn' },
                { key: this.$i18n.locale === 'en' ? 'com_title' : 'com_title_bn' },
                { key: this.$i18n.locale === 'en' ? 'service_name' : 'service_name_bn' },
                { key: this.$i18n.locale === 'en' ? 'status_name' : 'status_name_bn' }
            ]
        var serial = 0
        const listData = this.complainInfoDataList.map(item => {
            serial += 1
            const rowData = keys.map((keyItem, index) => {
                if (keyItem.key === 'serial_no') {
                    return { text: this.$n(serial) }
                }

            return { text: item[keyItem.key] }
            })

            return rowData
        })

        return listData
        },
        excelExport () {

        }
    }
}
</script>
